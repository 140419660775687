<template>
  <div id="question"
       class="scroll-mt-[18vw] md:scroll-mt-[6vw] flex-col bg-purple text-white flex justify-center items-center md:min-h-[40vw] md:rounded-[1.25vw] overflow-hidden relative px-[6vw] md:px-0 py-[10vw] md:py-0 min-h-[128vw] rounded-[5vw]"
  >
    <SvgShapeArrowStraight class="text-white absolute w-[33vw] left-[-6vw] top-[6vw] md:w-[16vw] md:left-[-2vw] md:top-[5vw]" />
    <SvgShapeGearSharp class="text-pink absolute w-[51vw] left-[-22vw] bottom-[-22vw] md:left-[7vw] md:bottom-[-6vw] md:w-[15vw]" />
    <SvgShapeHalfCircle class="text-yellow absolute md:top-[-2vw] right-[4vw] top-[4vw] md:right-[30vw] w-[28vw] md:w-[12vw]" />
    <SvgShapeCrescent class="absolute text-turquoise md:bottom-[-1vw] right-[6vw] bottom-[-12vw] md:right-0 w-[20vw] md:w-[9vw]"
                      :rotate="-60"
    />

    <h2 class="max-w-[56vw] text-[12vw] leading-[12vw] md:text-[5vw] md:leading-[5vw] tracking-[-0.01em] font-extrabold text-center">
      Got a question?
    </h2>
    <div class="md:max-w-[44vw] mt-[6vw] text-[4.25vw] text-center leading-[6vw] md:mt-[2vw] md:text-[1.5vw] md:leading-[2vw] tracking-[-0.01em]">
      Can’t find what you’re looking for in our FAQs? No worries. Get in touch and we’ll try to help!
    </div>
    <div>
      <ButtonRegular class="mt-[6vw] md:mt-[3vw]"
                     @click="openModal"
      >
        Send a message
      </ButtonRegular>
    </div>

    <SupportCardPopup v-model="isOpen" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isOpen = ref(false);

function openModal() {
  isOpen.value = true;
}
</script>
